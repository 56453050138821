<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitCreate">

      <!-- name -->
      <div class="form-group">
        <label for="name">{{$t('views.points.point_name')}}</label>
        <input
            id="name"
            v-model="data.name"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.name.$error }"
        />
        <div v-if="submitted && $v.data.name.$error" class="invalid-feedback">
          <span v-if="!$v.data.name.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.data.name.minLength">
                        {{$t('validatePhrases.minLength')}}: 1
                      </span>
          <span v-if="!$v.data.name.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 255
                      </span>
        </div>
      </div>

      <!-- adress -->
      <div class="form-group">
        <label for="address">{{$t('views.points.point_address')}}</label>
        <textarea
            id="address"
            v-model="data.address"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.address.$error }"
        ></textarea>
        <div v-if="submitted && $v.data.address.$error" class="invalid-feedback">
          <span v-if="!$v.data.address.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.data.address.minLength">
                        {{$t('validatePhrases.minLength')}}: 1
                      </span>
          <span v-if="!$v.data.address.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 255
                      </span>
        </div>
      </div>

      <!-- longtitude -->
      <div class="form-group">
        <label for="longtitude">{{$t('views.points.longtitude')}}</label>
        <input
            id="longtitude"
            v-model="data.longtitude"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.longtitude.$error }"
        />
        <small class="form-text text-muted">{{$t('popularWords.example')}}: 37.595270</small>
        <div v-if="submitted && $v.data.longtitude.$error" class="invalid-feedback">
          <span v-if="!$v.data.longtitude.required">{{$t('validatePhrases.requiredField')}}</span>
        </div>
      </div>

      <!-- latitude -->
      <div class="form-group">
        <label for="latitude">{{$t('views.points.latitude')}}</label>
        <input
            id="latitude"
            v-model="data.latitude"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.latitude.$error }"
        />
        <small class="form-text text-muted">{{$t('popularWords.example')}}: 55.736037</small>
        <div v-if="submitted && $v.data.latitude.$error" class="invalid-feedback">
          <span v-if="!$v.data.latitude.required">{{$t('validatePhrases.requiredField')}}</span>
        </div>
      </div>

      <!-- city_id -->
      <div class="form-group">
        <label for="city_id">{{$t('views.points.city')}}</label>
        <select v-model="data.city_id" class="form-control form-control-sm">
          <option v-for="item in cities" :key="item.id" :value="item.id">
            {{item.city_name}} ({{item.country.country_name}})
          </option>
        </select>
        <div v-if="submitted && $v.data.city_id.$error" class="invalid-feedback">
          <span v-if="!$v.data.city_id.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.data.city_id.minValue">
            {{$t('validatePhrases.minValue')}}: 1
          </span>
        </div>
      </div>

      <!-- metro_station_id -->
      <div class="form-group">
        <setter-metro v-model="data.metro_station_id"></setter-metro>
      </div>

      <!-- timezone -->
      <div class="form-group">
        <label for="timezone">{{$t('views.printers.timezone')}}</label>
        <select v-model="data.timezone" class="form-control form-control-sm">
          <option v-for="item in timezones" :key="item.id" :value="item.id">{{item.name}}</option>
        </select>
      </div>

      <!-- directions -->
      <div class="form-group">
        <label for="directions">{{$t('views.printers.howToFind')}}</label>
        <textarea
            rows="6"
            id="directions"
            v-model="data.directions"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.directions.$error }"
        ></textarea>
        <div v-if="submitted && $v.data.directions.$error" class="invalid-feedback">
          <span v-if="!$v.data.directions.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.data.directions.minLength">
            {{$t('validatePhrases.minLength')}}: 1
          </span>
          <span v-if="!$v.data.directions.maxLength">
            {{$t('validatePhrases.maxLength')}}: 5000
          </span>
        </div>
      </div>

      <!-- notice -->
      <div class="form-group">
        <label for="notice">{{$t('views.points.notice')}}</label>
        <textarea
            rows="6"
            id="notice"
            v-model="data.notice"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.data.notice.$error }"
        ></textarea>
        <div v-if="submitted && $v.data.notice.$error" class="invalid-feedback">
          <span v-if="!$v.data.notice.minLength">
            {{$t('validatePhrases.minLength')}}: 1
          </span>
          <span v-if="!$v.data.notice.maxLength">
            {{$t('validatePhrases.maxLength')}}: 255
          </span>
        </div>
      </div>

      <!-- currency_id -->
      <div class="form-group">
        <label for="currency_id">{{$t('views.printers.printerCurrency')}}</label>
        <select v-model="data.currency_id" class="form-control form-control-sm">
          <option v-for="item in currencyList" :key="item.id" :value="item.id">{{item.currency_name}}</option>
        </select>
      </div>

      <!-- is_on -->
      <div class="custom-control custom-checkbox mt-4 mb-3">
        <input v-model="data.is_on" type="checkbox" id="is_on" class="custom-control-input">
        <label for="is_on" class="custom-control-label">{{$t('views.printers.isOn')}}</label>
      </div>

      <!-- is_production -->
      <div class="custom-control custom-checkbox mb-3">
        <input v-model="data.is_production" type="checkbox" id="is_production" class="custom-control-input">
        <label for="is_production" class="custom-control-label">{{$t('views.printers.production')}}</label>
      </div>

      <!-- franchiseRelation -> franch_rel_id -->
      <div class="form-group">
        <label for="franch_rel_id">{{$t('views.printers.franchise')}}</label>
        <select v-model="data.franchiseRelation.franch_rel_id" class="form-control form-control-sm">
          <option v-for="item in legalRelations" :key="item.id" :value="item.id">
            {{item.legalMain.legal_person_name}} (->{{item.legalSlave.legal_person_name}})
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="printerData_schedule">{{$t('views.printers.schedule')}}</label>
        <week-scheduler
            :default="workSchedule"
            @emitComponent="setWorkSchedule"
        ></week-scheduler>
      </div>

      <div class="form-group">
        <label for="printerData_schedule_custom">{{$t('views.printers.scheduleCustom')}}</label>
        <printer-custom-schedule
          :default="workScheduleCustom"
          @emitComponent="setWorkScheduleCustom"
        ></printer-custom-schedule>
      </div>

      <div class="form-group">
        <label for="printerData_partner_percent">{{$t('views.points.services_and_price')}}</label>
        <set-services-list 
          :key="'edit'"
          :is="'set-services-list'"
          :servicesList="getServicesListCopy"
          :default="defaultForServList"
          @emitComponent="setServicesList"
        ></set-services-list>
      </div>


    </form>
  </div>
</template>

<script>

import {maxLength, minLength, minValue, required} from "vuelidate/lib/validators";
//import axios from "axios";
import WeekScheduler from "@/components/WeekScheduler";
// import PrinterPrice from "@/components/management/printers/PrinterPrice";
import PrinterCustomSchedule from "@/components/management/printers/PrinterCustomSchedule.vue";
import SetServicesList from './SetServicesList.vue';
import CopyArrayAndObject from "../../libraries/CopyArrayAndObject";
import SetterMetro from '../../management/metro/setterMetro.vue';

const copyArrObj = new CopyArrayAndObject();

export default {
  name: "EditObject",
  components: { WeekScheduler, PrinterCustomSchedule, SetServicesList, SetterMetro },
  data(){
    return {
      workSchedule: [],
      workScheduleCustom: [],
      data: {
        legal_person_id: 0,
        name: '',
        address: '',
        latitude: '',
        longtitude: '',
        city_id: '',
        metro_station_id: '0',
        timezone: '3',
        directions: '',
        notice: '',
        currency_id: 1,
        is_on: 1,
        is_production: 0,
        franchiseRelation: {franch_rel_id: 0},
        workSchedule: [],
        workScheduleCustom: [],
        services: []
      },
      defaultForServList: [],
      submitted: false,
    }
  },
  props: {
    object: Object,
    currencyList: Array,
    //metroStations: Array,
    timezones: Object,
    //franchises: Array,
    legalRelations: Array,
    cities: Array,
    servicesList: Array,
    submit: Number,
  },
  watch: {
    object: function(point){
      let obj = {};
      for(let key in point){
        obj[key] = point[key];
      }
      this.data = obj;
      this.defaultForServList = copyArrObj.copy(this.data.services);
      this.workSchedule = copyArrObj.copy(this.data.workSchedule);
      this.workScheduleCustom = copyArrObj.copy(this.data.workScheduleCustom);
    },
    submit: function(val){
      if(val > 0){
        this.submitCreate();
      }
    }
  },
  computed: {
    getServicesListCopy(){
      return copyArrObj.copy(this.servicesList);
    },
  },
  validations: {
    data: {
      name: { required, minLength: minLength(1), maxLength: maxLength(255) },
      address: { required, minLength: minLength(1), maxLength: maxLength(255) },
      longtitude: { required },
      latitude: { required },
      city_id: { required, minValue: minValue(1) },
      directions: { required, minLength: minLength(1), maxLength: maxLength(5000) },
      notice: { maxLength: maxLength(255) },
      franchiseRelation: {
        franch_rel_id: { required, minValue: minValue(1) }
      }
    }
  },
  methods: {
    setServicesList(val){
      this.data.services = val;
    },
    setWorkSchedule(val){
      this.data.workSchedule = val;
    },
    setWorkScheduleCustom(val){
      this.data.workScheduleCustom = val;
    },
    submitCreate(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.data.$touch();
      if(!this.$v.data.$invalid){
        this.$emit('submitEditObject', this.data);
      }
    },
  },
  created() {

  }

};
</script>

<style scoped lang="css">

</style>
