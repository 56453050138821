<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">{{$t('views.points.points_list')}}</div>

            <div class="row">
              <div class="col-lg-12">
                <div class="points--actions">
                  <div class="search">
                    <div class="form-group">
                      <input v-model="searchString" type="text" :placeholder="$t('navbar.search.text')" class="form-control form-control-sm">
                    </div>
                  </div>
                  <div class="create">
                    <div @click="showAddPopup" class="btn btn-sm btn-success">
                      <i class="fas fa-plus"></i> {{$t('popularWords.add')}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table table-hover" style="cursor: pointer;">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>{{$t('views.points.name')}}</th>
                    <th>{{$t('views.printers.isOn')}}</th>
                    <th>{{$t('views.printers.production')}}</th>
                    <th>{{$t('views.printers.address')}}</th>
                    <th>{{$t('views.points.services_list')}}</th>
                    <th>{{$t('views.points.franchise')}}</th>
                    <th>{{$t('views.printers.actions')}}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in computedPoints" :key="item.id">
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.id}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.name}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.is_on}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.is_production}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">{{item.address}}</td>
                    <td @click="showEditPopup(Object.assign({}, item))">
                      <div v-if="item.services.length === 0">-</div>
                      <div v-if="item.services.length > 0">
                        <span v-for="serv in item.services" :key="serv.id">{{serv.name}}; </span>
                      </div>
                    </td>
                    <td @click="showEditPopup(Object.assign({}, item))">
                      <div v-if="item.franchiseRelation.franchRel">
                        {{item.franchiseRelation.franchRel.legalSlave.franchise.franchise_name}} 
                        ({{item.franchiseRelation.franchRel.legalSlave.legal_person_name}})
                      </div>
                      <div v-if="!item.franchiseRelation.franchRel">-</div>
                    </td>
                    <td>
                      <div @click.prevent="showRemovePopup(item.id)" class="btn btn-sm btn-danger">
                        <i class="fa fa-times"></i>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div> 
      </div>

    </div>

    <popup
      :key="'create'"
      :closeButton="addPopup.closeButton"
      :actionButton="addPopup.actionButton"
      :actionClass="addPopup.actionClass"
      :show="addPopup.show"
      @closePopup="closeAddPopup"
      @actionPopup="addPopupSubmit"
    >
      <div slot="header">{{$t('views.points.create_point')}}</div>
      <div slot="body">
        <create-object
          :key="'create'"
          :legal-relations="legal_relations"
          :submit="submitCreatePoint"
          :cities="cities"
          :timezones="timezones"
          :currency-list="currency_list"
          :servicesList="servicesList"
          @submitCreateObject="submitCreateObject"
        ></create-object>
      </div>
    </popup>

    <popup
      :key="'edit'"
      :closeButton="editPopup.closeButton"
      :actionButton="editPopup.actionButton"
      :actionClass="editPopup.actionClass"
      :show="editPopup.show"
      @closePopup="closeEditPopup"
      @actionPopup="editPopupSubmit"
    >
      <div slot="header">{{$t('views.points.edit_point')}}</div>
      <div slot="body">
        <edit-object
          :key="'edit'"
          :legal-relations="legal_relations"
          :cities="cities"
          :timezones="timezones"
          :currency-list="currency_list"
          :servicesList="servicesList"
          :object="editPoint"
          :submit="submitEditPoint"
          @submitEditObject="submitEditObject"
        ></edit-object>
      </div>
    </popup>


    <popup
      :closeButton="removePopup.closeButton"
      :actionButton="removePopup.actionButton"
      :actionClass="removePopup.actionClass"
      :show="removePopup.show"
      @closePopup="closeRemovePopup"
      @actionPopup="removePopupSubmit"
    >
      <div slot="header">{{$t('views.points.delete_point')}}</div>
      <div slot="body">
        {{$t('views.points.delete_point_text')}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
import Popup from "../../../components/Popup.vue";
import CreateObject from '../../../components/services/objects/CreateObject.vue';
import EditObject from '../../../components/services/objects/EditObject.vue';

const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Popup,
    CreateObject,
    EditObject
},
  data() {
    return {
      servicesList: [],
      points: [],
      searchString: '',
      legal_relations: [],
      cities: [],
      //metro_stations: [],
      timezones: {},
      currency_list: [],
      submitCreatePoint: 0,
      submitEditPoint: 0,

      editPoint: {},

      deletedPointId: 0,

      addPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      editPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      removePopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.confirm'),
        actionClass: "btn-success"
      },
      title: this.$t('menuitems.services.list.points'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.services.list.points'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {
    computedPoints(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.points){
        if(searchStr == ''){
          result[result.length] = this.points[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          
          let legalName = ''+this.points[key].franchiseRelation.franchRel.legalSlave.legal_person_name +'';
          if(regexp.test(legalName)){
            isSearched = true;
            result[result.length] = this.points[key];
          }

          if(!isSearched){
            let franchiseName = ''+this.points[key].franchiseRelation.franchRel.legalSlave.franchise.franchise_name +'';
            if(regexp.test(franchiseName)){
              isSearched = true;
              result[result.length] = this.points[key];
            }
          }
          
          
          if(!isSearched){
            let address = ''+this.points[key].address +'';
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.points[key];
            }
          }
          if(!isSearched){
            let name = ''+this.points[key].name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.points[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    closeAddPopup(){
      this.addPopup.show = false;
    },
    showAddPopup(){
      this.addPopup.show = true;
    },
    addPopupSubmit(){
      this.submitCreatePoint++;
    },
    submitEditObject(data){
      let formData = new FormData();
      for(let key in data){
        if( typeof data[key] === 'object'){
          formData.append(key, JSON.stringify(data[key]));
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/services-points/update`, formData)
          .then(resp => {
            console.log(resp.data);
            this.closeEditPopup();
            this.getPoints();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    submitCreateObject(data){
      let formData = new FormData();
      for(let key in data){
        if( typeof data[key] === 'object'){
          formData.append(key, JSON.stringify(data[key]));
        }else{
          formData.append(key, data[key]);
        }
      }
      axios
          .post(`/v1/services-points/create`, formData)
          .then(resp => {
            if(resp.data.result == 'ok'){
              this.closeAddPopup();
              this.getPoints();
              this.$store.dispatch("addNotification", {
                text: this.$t('popularWords.successfully'),
                time: 3,
                color: "success"
              });
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    closeRemovePopup(){
      this.removePopup.show = false;
    },
    showRemovePopup(pointId){
      this.deletedPointId = pointId;
      this.removePopup.show = true;
    },
    removePopupSubmit(){
      let formData = new FormData();
      formData.append("id", this.deletedPointId);
      axios
          .post(`/v1/services-points/delete`, formData)
          .then(() => {
            this.closeRemovePopup();
            this.$store.dispatch("addNotification", {
              text: this.$t('popularWords.successfully'),
              time: 3,
              color: "success"
            });
            this.getPoints();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editPopupSubmit(){
      this.submitEditPoint++;
    },
    showEditPopup(obj){
      let copyObj = copyArrObj.copy(obj);
      this.editPoint = copyObj;
      this.editPopup.show = true;
    },
    closeEditPopup(){
      this.editPopup.show = false;
    },
    getPoints(){
      axios
          .post(`/v1/services-points/get-all`)
          .then(resp => {
            this.points = resp.data;
            for(let key in this.points){
              this.points[key].metro_station_id = '' + this.points[key].metro_station_id + '';
            }
            this.getCurrencyList();
          })
          .catch(err => {``
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCurrencyList(){
      axios
          .get(`/v1/configs/currency`)
          .then(resp => {
            this.currency_list = resp.data;
            this.getTimezones();
            //this.getMetroStations();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    // getMetroStations(){
    //   axios
    //       .get(`/v1/configs/metro-stations`)
    //       .then(resp => {
    //         this.metro_stations = resp.data;
    //         this.getTimezones();
    //       })
    //       .catch(err => {
    //         this.$store.dispatch("addNotification", {
    //           text: err.response.data.message,
    //           time: 6,
    //           color: "danger"
    //         });
    //       });
    // },
    getTimezones(){
      axios
          .get(`/v1/configs/timezones`)
          .then(resp => {
            this.timezones = resp.data;
            //this.getFranchisies();
            this.getLegalRelations();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getFranchisies(){
      axios
          .get(`/v1/franchise/get-all`)
          .then(resp => {
            this.franchises = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getCities(){
      axios
        .get(`/v1/configs/cities`)
        .then(resp => {
          this.cities = resp.data;
          this.getServicesList();
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    getServicesList(){
      axios
        .get(`/v1/services-list/get-all`)
        .then(resp => {
          this.servicesList = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    getLegalRelations(){
      axios
          .get(`/v1/legal-relations/get-all`)
          .then(resp => {
            this.legal_relations = resp.data;
            this.getCities();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getPoints();
  }
};
</script>

<style scoped lang="scss">
.points--actions{
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;

  .search{
    margin-right: 15px;
  }
}
</style>
